<template>
	<div class="help-details">
		<div class="headline">
			<p class="name">{{detailsData.headline}}</p>
			<p class="back" @click="back">返回</p>
		</div>
		<div class="com">
			<p>厦门国际商品拍卖有限公司是国内贸易部和厦门市人民政府批准成立的国家级综合性拍卖企业，是第一批被评为“中国拍卖AAA级”的企业，并且至今连续被评为“中国拍卖AAA级”的企业，也是国内率先通过ISO9001：2000质量管理体系认证的拍卖企业。</p>
			<p>公司于1996年《中华人民共和国拍卖法》颁布后成立，注册资金人民币1000万元，被国内贸易部确定为全国大型拍卖企业试点单位。</p>
			<p>总部在厦门市湖里区安岭路999号301A，下设办公室、业务部、拍卖部、法律事务部、账务部、仓储部等部门</p>
			<p>拥有自有拍卖厅，设备齐全</p>
			<p>现有四名拍卖师，二十一名从业人员，拥有一个专业的业务策划团队，专项负责重要标的的拍卖招标和销售策划</p>
			<p>政府指定的公物拍卖机构，也是海关总署、原国家经贸委联合批准的缉私罚没车定点拍卖企业</p>
			<p>业务范围涵盖各类房地产、股权、债权、产权交易、中央大型企业资产处置、破产企业财产、工商罚没资产、海关罚没资产、知识产权、机动车、机器设备、物资等标的物的拍卖</p>
			<p>成立以来在多年的业务拓展过程中，积累了丰富的异地拍卖经验，并建立了庞大的全国招商数据库、客户数据库根据标的的特点进行分类，可依据需求调出相关的客户群体，有针对性地进行宣传推介</p>
			<p>熟悉国家政策法规，以严谨规范、专业高效、优势服务为运作标准，成功的组织了千余场拍卖会，累计成交额达百亿余元</p>

		</div>
	</div>
</template>

<script>
	export default {
		props: ['detailsData'],
		data() {
			return {
				
			};
		},
		methods: {
			back(){
				this.$emit("back")
			}
		}
	}
</script>

<style lang="less" scoped>
	.help-details {
		.headline {
			height: 60px;
			line-height: 60px;
			display: flex;
			justify-content: space-between;
			font-size: 20px;
			color: #333;

			.name {
				font-weight: bold;
			}

			.back {
				font-size: 16px;
				cursor: pointer;
			}
		}
		.com{
			padding-bottom: 30px;
			p {
			    font-size: 14px;
			    text-indent: 2rem;
			    color: #333;
			    padding-bottom: 30px;
			    line-height: 24px;
			}
		}
	}
</style>
