<template>
	<div class="helpLayout container">
		<!-- 侧边栏导航 -->
		<menus @onSelect="onSelect"></menus>
		
		<div class="help-right">
			<!-- 列表 -->
			<div class="list-item" v-if="listDetails == 1">
				<helpList :helpMenuList="helpMenuList" @onListItem="onListItem"></helpList>
			</div>
			
			<!-- 详情 -->
			<div class="details-item" v-else>
				<helpDetails :detailsData="detailsData" @back="back"></helpDetails>
			</div>
			
			<!-- <router-view></router-view> -->
		</div>
	</div>
</template>

<script>
	import menus from '@/components/help/menu.vue'
	import helpList from '@/components/help/helpList.vue'
	import helpDetails from '@/components/help/helpDetails.vue'
	export default{
		data(){
			return{
				listDetails: 1,
				helpMenuList: {
					menuTitle: '关于我们',
					list: [{
						listTitle: '公司简介',
						time: '2020-7-15 18:32:58'
					}]
				},
				detailsData: {
					headline: '公司简介',
					content: ''
				},
				menuValue: '',
			}
		},
		components: {
			menus,
			helpList,
			helpDetails
		},
		methods:{
			// 左边菜单点击返回的值
			onSelect(key){
				console.log(key)
				this.listDetails = 1
				this.menuValue = key
				this.onMenu()
			},
			// 左边选中菜单项的index
			onMenu(){
				if(this.menuValue == '1'){
					this.helpMenuList = {
						menuTitle: '关于我们',
						list: [{
							listTitle: '公司简介',
							time: '2020-7-15 18:32:58'
						}]
					}
					this.detailsData = {
						headline: '公司简介',
						content: ''
					}
					return
				}
				if(this.menuValue == '2'){
					this.helpMenuList = {
						menuTitle: '平台资讯',
						list: []
					}
					return
				}
				if(this.menuValue == '3'){
					this.helpMenuList = {
						menuTitle: '问题解答',
						list: [{
							listTitle: '如何发布拍品？',
							time: '2020-7-15 18:32:58'
						}]
					}
					this.detailsData = {
						headline: '如何发布拍品？',
						content: ''
					}
					return
				}
				if(this.menuValue == '4'){
					this.helpMenuList = {
						menuTitle: '生态伙伴',
						list: [{
							listTitle: '友情链接',
							time: '2020-7-15 18:32:58'
						}]
					},
					this.detailsData = {
						headline: '友情链接',
						content: ''
					}
					return
				}
				if(this.menuValue == '5'){
					this.helpMenuList = {
						menuTitle: '竞拍指南',
						list: []
					},
					this.detailsData = {
						headline: '',
						content: ''
					}
					return
				}
				if(this.menuValue == '6'){
					this.helpMenuList = {
						menuTitle: '竞拍公告',
						list: [{
							listTitle: '闽DJE907福特小型普通客车（事故未修复车辆）',
							time: '2020-7-15 18:32:58'
						}]
					},
					this.detailsData = {
						headline: '闽DJE907福特小型普通客车（事故未修复车辆）',
						content: ''
					}
					return
				}
				if(this.menuValue == '7'){
					this.helpMenuList = {
						menuTitle: '公司视频',
						list: []
					},
					this.detailsData = {
						headline: '',
						content: ''
					}
					return
				}
				if(this.menuValue == '8'){
					this.helpMenuList = {
						menuTitle: 'PPT',
						list: []
					},
					this.detailsData = {
						headline: '',
						content: ''
					}
				}
			},
			// 点击返回
			back(){
				this.listDetails = 1
			},
			// 点击列表跳转详情
			onListItem(){
				this.listDetails = 2
				
				this.onMenu()
			}
		}
	}
</script>

<style lang="less" scoped>
	.helpLayout{
		margin: 40px auto;
		display: flex;
		.help-right{
			width: 930px;
			background: #fff;
			padding: 0 20px;
			box-sizing: border-box;
		}
	}
</style>
