import Vue from "vue"

import Router from 'vue-router'
import layout from "../views/layout.vue"
import help from "../views/help/index.vue"

Vue.use(Router)

let routes = [
	{
		path: '/',
		name: 'layout',
		component: layout,
		children: [
			{
				path: '/index',
				name: 'index',
				meta: { title: '首页', active: 0},
				component: ()=>import('../views/index/index.vue')
			},
			{
				path: '/details',
				name: 'details',
				meta: { title: '详情', active: 1},
				component: ()=>import('../views/details/details.vue')
			},
			{
				path: '/list',
				name: 'list',
				meta: { title: '标的', active: 1},
				component: ()=>import('../views/list/list.vue')
			},
			{
				path: '/help',
				name: 'help',
				meta: { title: '帮助中心', active: 5},
				component: help,
			},
			{
				path: '/personal',
				name: 'personal',
				meta: { title: '个人中心', active: 6},
				component: ()=>import('../views/personal/personal.vue'),
			},		
		],
	},
	{
		path: '/login',
		name: 'login',
		meta: { title: '登录', active: 0},
		component: ()=>import('../views/login/login.vue')
	},
	{
		path: '/enroll',
		name: 'enroll',
		meta: { title: '注册类型', active: 0},
		component: ()=>import('../views/register/enroll.vue')
	},
	{
		path: '/register',
		name: 'register',
		meta: { title: '注册', active: 0},
		component: ()=>import('../views/register/register.vue')
	},
	{
		path: '/attestation',
		name: 'attestation',
		meta: { title: '实名认证', active: 0},
		component: ()=>import('../views/attestation/attestation.vue')
	},
	{
		path: '/forgetPassword',
		name: 'forgetPassword',
		meta: { title: '忘记密码', active: 0},
		component: ()=>import('../views/register/forgetPassword.vue')
	},
	{
		path: "*",
		redirect: {name: "index"}
	}
]
	

// 解决报错
const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace
// push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
Router.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

export default new Router({
	// mode: 'history',
	routes
})