/**
 * 定义全局过滤器
 * 
 * 过滤订单状态
 * 
 */

let globalFilter = {
	// 标的状态
	goodsStatu: statu => {
	    let result = ''
	    switch(statu){
	        case 1:
	            result = '即将开始'
	        break;
	        case 2:
	            result = '正在进行'
	        break;
	        case 3:
	            result = '已成交'
	        break;
	        case 4:
	            result = '已流拍'
	        break;
	        case 5:
	            result = '已撤拍'
	        break;
	    }
	    return result
	},
	// 出价状态
	bidStatu: statu => {
		let result = ''
		switch(statu){
			case 1:
				result = '领先'
			break;
			case 2:
				result = '出局'
			break;
		}
		return result
	},
	
    // 时间戳转换日期
    formatDate: value => {
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? ('0' + MM) : MM;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        let h = date.getHours();
        h = h < 10 ? ('0' + h) : h;
        let m = date.getMinutes();
        m = m < 10 ? ('0' + m) : m
        let s = date.getSeconds();
        s = s < 10 ? ('0' + s) : s;
        return y + '年' + MM + '月' + d + '日 ' + h + ':' + m + ':' + s;
    },

}


export default globalFilter