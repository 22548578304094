import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router/router'

// 引入ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

// 注册全局过滤器
import globalFilter from '@/utils/filters'
console.log(globalFilter)

Object.keys(globalFilter).forEach(key => {
    Vue.filter(key, globalFilter[key])
})

// 引入路由配置
// import '@/router/routingConfiguration.js'

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
