<template>
	<div class="header">
		<div class="container container-outtitle">
			<!-- 已登录 -->
			<div class="login-info" v-if="isLogin == 1">
				<!-- <router-link to="" class="">消息</router-link> -->
				<div class="user-item">
					<span>{{userInfo.name?userInfo.name:userInfo.mobile}}</span>
					<img src="../assets/img/arrows.png" alt="">
					<div class="pull-down">
						<router-link to="/personal">个人中心</router-link>
						<span @click="quit">退出</span>
					</div>
				</div>
			</div>
			<!-- 未登录 -->
			<div class="nologin-info" v-else>
				<router-link to="/enroll">免费注册</router-link>
				<router-link to="/login">登录</router-link>
			</div>
		</div>
		<div class="nav-con">
			<div class="container h70">
				<router-link to="/index" class="logo">
					<img src="../assets/img/headerlogo.png" alt="">
				</router-link>
				<div class="nav-wrap clearfix">
					<ul class="main-nav">
						<li :class="active==index? 'active' : '' " v-for="(item,index) in navList" :key="index" @click="navItem(index)">
							<router-link :to="item.url" class="nav-a">{{item.title}}</router-link>
						</li>
					</ul>
					<div class="nav-search">
						<div class="other-nav">
							<router-link to="" class="color-333">我的竞拍</router-link>
						</div>
						<el-input placeholder="搜索标的" size="mini" v-model="searchInput" class="">
							<el-button slot="append" icon="el-icon-search"></el-button>
						</el-input>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				navList: [{
					title: '首页',
					url: '/index'
				},{
					title: '标的',
					url: '/list'
				},{
					title: '拍卖会',
					url: '/auction'
				},{
					title: '拍卖公告',
					url: '/auctionNotice'
				}],//导航栏
				active: 0,//当前选中页面
				searchInput: '',//搜索
				isLogin: 0, //
				userInfo: null, //用户信息
			}
		},
		created() {			
			this.handleGetActive()
			// 更新登录状态
			this.userInfo = JSON.parse(sessionStorage.getItem('userInfo')) 
			// this.userInfo = JSON.parse(localStorage.getItem('userInfo')) 
			if(this.userInfo){
				this.isLogin = this.userInfo.isLogin
			}
			console.log(this.isLogin)
		},
		methods:{
			navItem(index){
				this.active = index
				this.$emit("menuValue")
			},		
			//处理当前页面是哪个菜单激活active
			handleGetActive(){
				let str = this.$route.meta.active ? this.$route.meta.active : 0
				this.active = str
			},
			// 退出登录
			quit(){
				this.$router.push({path: '/login'})
				sessionStorage.removeItem('userInfo')
				// localStorage.removeItem('userInfo')
			}
		},
		watch: {
			$route(to,from){
				this.handleGetActive()
			}
		}
	}
</script>

<style lang="less" scoped>
	.header {
		width: 100%;
		background: #fff;	
		.container-outtitle {
			height: 36px;	
			.nologin-info {
				height: 36px;
				line-height: 36px;
				color: #666;
				float: right;
				overflow: hidden;
	
				a {
					float: left;
					color: #333;
					font-size: 14px;
					margin-left: 30px;
				}
			}
			.login-info{
				height: 36px;
				line-height: 36px;
				color: #666;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				color: #333;
				font-size: 14px;
				>a{
					padding: 0 15px 0 20px;
					border-right: solid 1px #d7d7d7;
				}
				.user-item{
					min-width: 60px;
					padding: 0 0px 0 10px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					position: relative;
					>img{
						width: 20px;
						margin-left: 10px;
					}
					&:hover{
						>img{
							transform: rotate(180deg);
						}
						.pull-down{
							display: block;
						}
					}
				}
				.pull-down{
					position: absolute;
					top: 36px;
					left: 0;
					z-index: 99;
					width: 100%;
					background: #fff;
					text-align: center;
					box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
					padding-top: 6px;
					display: none;
					a,span{
						display: block;
						&:hover{
							color: #d7332d;
						}
					}
				}
			}
		}
	
		.nav-con {
			.h70{
				height: 70px;
			}
			height: 70px;
			border-top: 1px solid #C1393C;
			background: #fff;
			position: relative;
			overflow: hidden;
			.logo {
				display: block;
			    text-align: left;
			    float: left;
			    padding: 5px 0;
				// height: 70px;
			    // width: 217px;
				img{
					display: block;
					height: 60px;
				}
			}
			.nav-wrap {
			    margin-left: 100px;
			    padding: 20px 0;
			    position: relative;
				height: 30px;
			    width: 983px;
				.main-nav {
				    margin-right: 250px;
					overflow: hidden;
					li {
					    float: left;
						.nav-a {
						    text-align: center;
						    padding: 8px 0;
						    display: inline-block;
						    margin-right: 42px;
						    position: relative;
						    font-size: 16px;
						    color: #333;
						    font-weight: 400;
							&:hover{
								color: #d7332d;
							}
						}
						&.active{
							a{
								color: #d7332d;
							}
						}
					}
				}
				.nav-search {
				    position: absolute;
				    right: 0;
				    top: 20px;
					.other-nav {
					    float: left;
					    width: 88px;
					    height: 34px;
					    text-align: center;
					    margin-right: 20px;
					    line-height: 34px;
					    font-size: 14px;
						a {
						    color: #333;
						    font-size: 16px;
						}
					}
					/deep/.el-input{
						width: 230px;
						height: 30px;
						position: relative;
						overflow: hidden;
						.el-input__inner{
							height: 30px;
							line-height: 30px;
						}
						.el-input-group__append, .el-input-group__prepend{
							background-color: #fff;
							color: #909399;
							padding: 0px;
							width: 36px;
							height: 28px;
							text-align: center;
							i{
								font-size: 14px;
								font-weight: bold;
								color: #d7332d;
							}
						}
					}
				}
			}
			
		}
	}
</style>
