<template>
	<el-menu :default-active="activeIndex" class="el-menu-vertical-demo" active-text-color="#d7332d" @select="handleSelect">
		<el-menu-item index="1">
			<span slot="title">关于我们</span>
		</el-menu-item>
		<el-menu-item index="2">
			<span slot="title">平台资讯</span>
		</el-menu-item>
		<el-menu-item index="3">
			<span slot="title">问题解答</span>
		</el-menu-item>
		<el-menu-item index="4">
			<span slot="title">生态伙伴</span>
		</el-menu-item>
		<el-menu-item index="5">
			<span slot="title">竞拍指南</span>
		</el-menu-item>
		<el-menu-item index="6">
			<span slot="title">竞拍公告</span>
		</el-menu-item>
		<el-menu-item index="7">
			<span slot="title">公司视频</span>
		</el-menu-item>
		<el-menu-item index="8">
			<span slot="title">PPT</span>
		</el-menu-item>
	</el-menu>
</template>

<script>
	export default {
		data() {
			return {
				activeIndex: '1'
			};
		},
		methods: {
			handleSelect(key, keyPath){
				console.log(key, keyPath);
				this.activeIndex = key
				this.$emit('onSelect',key)
			},
		}
	}
</script>

<style lang="less" scoped>
	.el-menu{
		width: 240px;
		margin-right: 30px;
		border-right: none;
		.el-menu-item{
			font-size: 16px;
			border-bottom: 1px solid #f5f5f5;
			position: relative;
			&:focus,&:hover,&.is-active{
				color: #d7332d;
				&::before{
					position: absolute;
					left: 0;
					top: 50%;
					width: 4px;
					height: 36px;
					background: #d7332d;
					margin-top: -18px;
					content: "";
				}
			}
			&:focus,&:hover{
				background: transparent;
			}
		}
	}
</style>
