<template>
	<div class="footer">
		<div class="judicial-school">
			<div class="container">
				<div class="footer-list" v-for="(item,index) in footerList" :key="index">
					<h3>{{item.title}}</h3>
					<ul class="jp-list">
						<li v-for="(value,key) in item.list">
							<router-link to="">{{value.text}}</router-link>
						</li>
					</ul>
				</div>
				<div class="footer-list develop-team">
					<h3>联系我们</h3>
					<ul>
						<li v-for="(item,index) in contactUs" :key="index">
							<div class="i-border">
								<i :class="item.icon"></i>
							</div>
							<p>
								<span>{{item.label}}</span>
								<span>{{item.num}}</span>
							</p>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="container footer-copy">
			<div class="copyright">
				Copyright © 2020 厦门国际商品拍卖有限公司 版权所有 ICP证：
				<router-link to="">
					<img src="../assets/img/license.jpeg" alt="">闽ICP备08011196号-3
				</router-link>
			</div>
			<div class="a-link">
				<p>技术支持：</p>
				<a href="https://www.tianyancha.com/company/3300564556">厦门蚂蚁智投科技有限公司</a>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				footerList: [{
						title: '关于我们',
						list: [{
							url: '',
							text: '公司简介'
						}]
					},
					{
						title: '友情链接',
						list: [{
								url: '',
								text: '国资委'
							},
							{
								url: '',
								text: '中拍协'
							},
							{
								url: '',
								text: '中新网'
							}
						]
					}
				],
				contactUs: [{
						icon: 'icon-phone',
						label: '客服电话',
						num: '0592-5110110'
					},
					{
						icon: 'icon-fex',
						label: '传真',
						num: '0592-5114666'
					},
					{
						icon: 'icon-qq',
						label: 'QQ客服',
						num: '38516946'
					},
					{
						icon: 'icon-mail',
						label: '邮箱',
						num: '38516946@qq.com'
					},
					{
						icon: 'icon-kf',
						label: '服务时间',
						num: '9:00-17:00'
					}]
			}
		}
	}
</script>

<style lang="less" scoped>
	.footer {
		background: #333;
		width: 100%;

		.judicial-school {
			width: 100%;
			border-top: 1px solid #d7d7d7;
			background: #fff;

			.footer-list {
				float: left;
				margin: 30px 0 16px 0px;
				width: 165px;

				h3 {
					color: #333;
					height: 18px;
					line-height: 18px;
					padding: 0 0 10px 10px;
					font-size: 18px;
				}

				ul {
					li {
						padding-left: 12px;
						margin-top: 14px;
						font-size: 14px;
						color: #666;

						a {
							position: relative;
							cursor: pointer;
							z-index: 1;
							font-size: 14px;
							color: #666;
						}
					}
				}
			}
			.develop-team {
			    width: 770px;
				ul {
				    display: flex;
				    flex-direction: row;
				    flex-wrap: wrap;
					li {
						display: flex;
						align-items: center;
					    width: 33.333%;
						font-size: 28px;
						margin-top: 9px;
						padding-left: 0;
						.i-border {
						    width: 40px;
						    height: 40px;
						    line-height: 40px;
						    border: solid 1px #d6363b;
						    border-radius: 50%;
						    text-align: center;
						    vertical-align: middle;
						    display: flex;
							justify-content: center;
							align-items: center;
						    margin-right: 10px;
							i {
							    margin-right: 0;
								height: 25px;
							    width: 25px;
							    display: inline-block;
							    position: relative;
							}
							.icon-phone {
							    background: url(../assets/img/icon-dd.png) no-repeat center center;
							    
							}
							.icon-fex{
								background: url(../assets/img/icon-fex.png) no-repeat center center;
							}							
							.icon-qq{
								background: url(../assets/img/icon-qq.png) no-repeat center center;
							}							
							.icon-mail{
								background: url(../assets/img/icon-mail.png) no-repeat center center;
							}							
							.icon-kf{
								background: url(../assets/img/icon-kf.png) no-repeat center center;
							}
							
						}
						p {
						    font-size: 14px;
						    display: inline-block;
							span{
								display: block;
								&:first-child{
									margin-bottom: 6px;
								}
							}
						}
					}
				}
			}
		}
		.footer-copy{
			padding: 20px 0;
			font-size: 14px;
		}
		.a-link{
			display: flex;
			justify-content: center;
			color: #666;
			text-align: center;
			margin-top: 8px;
			a{
				color: #666;
			}
		}
		.copyright{
			color: #666;
			text-align: center;
			line-height: 20px;
			// padding: 20px 0;
			font-size: 14px;
			a{
				color: #666;
				font-size: 14px;
			}
			img{
				width: 20px;
				height: auto;
				border-radius: 6px;
				margin-right: 5px;
			}
		}
	}
</style>
