<template>
	<div class="layout">
		<!-- 头部 -->
		<Header></Header>
		
		<router-view></router-view>
		
		<!-- 底部 -->
		<Footer></Footer>
	</div>
</template>

<script>
	import Header from '@/components/header.vue'
	import Footer from '@/components/footer.vue'
	export default{
		data(){
			return{
				
			}
		},
		components:{
			Header,
			Footer
		},
		methods:{
			
		}
		
	}
</script>

<style lang="less" scoped="">
	.layout {
		width: 100%;

	}
</style>
