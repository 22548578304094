<template>
	<div class="help-list">
		<div class="title">{{helpMenuList.menuTitle}}</div>
		<ul>
			<li v-for="(item,index) in helpMenuList.list" :key="index">
				<p @click="linkDetails">{{item.listTitle}}</p>
				<span>{{item.time}}</span>
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		props: ['helpMenuList'],
		data() {
			return {
				
			};
		},
		methods: {
			// 跳转详情
			linkDetails(){
				this.$emit("onListItem")
			}
		}
	}
</script>

<style lang="less" scoped>
	.help-list{
		.title{
			height: 60px;
			line-height: 60px;
			font-size: 20px;
			font-weight: bold;
			color: #333;
			border-bottom: solid 2px #d7332d;
		}
		li{
			height: 60px;
			line-height: 60px;
			display: flex;
			justify-content: space-between;
			border-bottom: solid 1px #f5f5f5;
			font-size: 14px;
			color: #333;
			p{
				cursor: pointer;
			}
		}
	}
</style>
