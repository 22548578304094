<template>
	<div id="app" v-cloak>
		<router-view></router-view>
	</div>
</template>

<script>
	import { mapMutations } from 'vuex'
	export default{
		data(){
			return{
				
			}
		},
		created() {
			// this.writeUserData()
		},
		methods:{
			...mapMutations(['updateLogin','updateUserInfo']),
			//在全局写入用户数据
			writeUserData(){
				let userData = localStorage.getItem('userData')
				if(userData){
					let data = JSON.parse(unescape(userData))
					this.updateLogin(true)
					this.updateUserInfo(data)
				}else{
					if(this.$route.name !== 'login'){
						this.$router.push('/login')
					}
				}
			},
		}
	}
</script>

<style lang="less">
	@import './assets/css/reset.css';
	#app {
		font-family: Source Han Sans SC, Avenir, Helvetica, Arial, sans-serif;
		width: 100%;
		height: 100%;
		background: #f4f5f5;
	}
	body{
		background: #f4f5f5;
	}
	img {
	    vertical-align: middle;
	    border: none;
	}
	address, cite, dfn, em, i, var {
	    font-style: normal;
	}
	[v-cloak]{
		display: none;
	}
	.container {
		width: 1200px;
		margin: 0 auto;
		display: block;
	}
	.clearfix {
	    zoom: 1;
	}
	.clearfix:after, .container-outtitle:after, .container:after, ul:after {
	    content: "";
	    clear: both;
	    visibility: hidden;
	}
	.clearfix:after, .container:after, ul:after {
	    display: block;
	    height: 0;
	}
	.tit {
	    font-size: 14px;
	    margin-top: 5px;
	}
	.color-333 {
	    color: #333;
	}
	.float-left {
	    float: left;
	}
	.pb10 {
	    padding-bottom: 10px;
	}
	
	.pr20 {
	    padding-right: 20px;
	}
	
	.mr0 {
	    margin-right: 0!important;
	}
	.mb10{
		margin-bottom: 10px;
	}
	.mb20{
		margin-bottom: 20px;
	}	
	.mt10{
		margin-top: 10px;
	}
	.mt15{
		margin-top: 15px;
	}
	.mt20{
		margin-top: 20px;
	}
	.mt40{
		margin-top: 40px;
	}
	.mt50{
		margin-top: 50px;
	}
	.f12 {
	    font-size: 12px;
	}
	.lineh16 {
	    line-height: 16px;
	}
	.main-tit {
	    display: block;
	    padding: 20px 0 0;
	    position: relative;
	}
	.main-tit-title {
	    font-size: 24px;
	    color: #333;
	}
	.more-count {
	    float: right;
	    line-height: 24px;
	    color: #666;
	    font-size: 12px;
	    top: 10px;
	    padding-right: 10px;
	    background: url(./assets/img/newicon2.png) -429px 6px no-repeat;
	}
	button, input[type=submit], input[type=reset], input[type=button] {
	    -webkit-appearance: none;
	}
	button, input, select, textarea {
	    outline: 0;
	    border-style: none;
	    font-family: arial,"Microsoft Yahei","Helvetica Neue",Helvetica,Arial,sans-serif;
	}
	.bg-white{
		background: #fff;
	}
</style>
