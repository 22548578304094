export default {
    //更改登录状态
    updateLogin(state,data){
        state.login = data
    },
    //更新用户信息
    updateUserInfo(state,data){
        let obj = JSON.parse(JSON.stringify(data))
        state.userInfo = obj
    },
}